<script setup lang="ts">

</script>

<template>
    <div class="icp-wrapper">
        <p class="text">ICP备案/许可证号：粤ICP备2023147469号-1</p>
        <p class="text">深圳市图南传媒科技有限公司</p>
    </div>
</template>

<style scoped lang="less">
.icp-wrapper {
    text-align: center;
    margin: 30px 0;
    font-size: 26px;
    line-height: 30px;
}
</style>