/** 广告位配置 */
export const SPACE_MAP = {
    Home_inter: [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6914857', space_js: '//fdpb.vanmatt.com/site/snm/openjs/v/wj/common/pko.js' }],
    Home_dialog: [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6914860', space_js: '//fdpb.vanmatt.com/production/mhg/common/p_qdjg/openjs/n.js' }],
    Home_banner: [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6914862', space_js: '//fdpb.vanmatt.com/site/ojir/sfl/common/i/static/y.js' }],
    Topic_native: [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6914861', space_js: '//fdpb.vanmatt.com/common/n/source/ih/resource/q/rekh/openjs/r.js' }],
    Discover_native: [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6914861', space_js: '//fdpb.vanmatt.com/common/n/source/ih/resource/q/rekh/openjs/r.js' }],
    Hot_native: [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6914861', space_js: '//fdpb.vanmatt.com/common/n/source/ih/resource/q/rekh/openjs/r.js' }],
    Rank_native: [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6914861', space_js: '//fdpb.vanmatt.com/common/n/source/ih/resource/q/rekh/openjs/r.js' }],
    TopicDetail_inter: [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6914858', space_js: '//fdpb.vanmatt.com/common/ton/w/production/x/k/common/qlq.js' }],
    TopicDetail_dialog: [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6914863', space_js: '//fdpb.vanmatt.com/site/p/static/k-j/resource/stgmj/c.js' }],
    TopicDetail_banner: [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6914865', space_js: '//fdpb.vanmatt.com/site/rm/l/production/uv_io/lj.js' }],
    TopicResult_inter: [{ space_src: 'baidujs', space_type: 'inters', space_id: 'u6914859', space_js: '//fdpb.vanmatt.com/source/upo/common/xy_lrm/production/x.js' }],
    TopicResult_dialog: [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6914866', space_js: '//fdpb.vanmatt.com/site/s/nm/source/vwj/p_mm.js' }],
    TopicResult_native: [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6914869', space_js: '//fdpb.vanmatt.com/production/vqpy/static/z_ms_p_y.js' }],
    TopicResult_banner: [{ space_src: 'baidujs', space_type: 'native', space_id: 'u6914867', space_js: '//fdpb.vanmatt.com/source/t-o-nwx/openjs/kqnp.js' }],
};
