import { ref, reactive } from 'vue';
import { getFunplayList, getFunplayDetail, getArticleDetail } from '@/apis/funplay';

const loading = ref<boolean>(false);
const funplayHotList = reactive<{ data: FunplayData[] }>({
    data: []
});
const hotPageConfig = { page: 0, limit: 20 };
const funplayRankList = reactive<{ data: FunplayData[] }>({
    data: []
})
const rankPageConfig = { page: 0, limit: 20 };

let randomList: FunplayData[] = [];

const getFunplayData = (type: FunplayRequestData['rank_name']) => {
    return new Promise(async (resolve) => {
        try {
            let data: FunplayRequestData = {
                page_id: 'home',
                channel: 'oppo-exam',
                rank_name: type,
                sub_page: type == 'hot' ? hotPageConfig.page : rankPageConfig.page,
                sub_limit: type == 'hot' ? hotPageConfig.limit : rankPageConfig.limit,
                screen_width: 375,
                screen_height: 740
            };
            let response: ListData<FunplayResponseData> = await getFunplayList(data);
            if (type == 'hot') {
                funplayHotList.data = funplayHotList.data.concat(response.data[0].data);
                if (data.sub_page == 1) {
                    randomList = response.data[0].data.slice().sort((a, b) => Math.random() - 0.5 > 0 ? 1 : -1);
                }
            } else {
                funplayRankList.data = funplayRankList.data.concat(response.data[0].data);
            }
            setTimeout(() => {
                loading.value = false;
            }, 500);
            resolve('success');
        } catch (error) {
            console.log(error);
            resolve(null);
        }
    })
}

let funplayDetail = reactive<{ data: FunplaySimpleData }>({
    data: {}
});
const getFunplayDetailData = async (id: string) => {
    let params: FunplayDetailRequestData = { id: id, channel: 'oppo-exam' };
    try {
        let response: FunplaySimpleData = await getFunplayDetail(params);
        funplayDetail.data = response;
        setTimeout(() => {
            loading.value = false;
        }, 500);
    } catch (error) {
        console.log(error);
    }
}

const articleDetail = reactive<{ data: ArticleData }>({
    data: {}
});
const getArticleDetailData = async (id: string) => {
    let params: ArticleDetailRequestData = { id: id };
    try {
        let response: ArticleData = await getArticleDetail(params);
        articleDetail.data = response;
        setTimeout(() => {
            loading.value = false;
        }, 500);
    } catch (error) {
        console.log(error);
    }
}

const getDailyList = (): FunplayData[] => {
    let resortRandomList = randomList.slice().sort((a, b) => Math.random() - 0.5 > 0 ? 1 : -1);
    return resortRandomList.slice(0, 4);
}

const getRecommendList = (): FunplayData[] => {
    return randomList.slice(4, 11);
}

const getNewList = (): FunplayData[] => {
    return randomList.slice(7, 15);
}

const getExactList = (): FunplayData[] => {
    return randomList.slice(11, 19);
}

export function useFunplay() {
    return {
        loading,
        funplayHotList,
        funplayRankList,
        hotPageConfig,
        rankPageConfig,
        getFunplayData,
        funplayDetail,
        getFunplayDetailData,
        articleDetail,
        getArticleDetailData,
        getDailyList,
        getRecommendList,
        getNewList,
        getExactList,
    };
}
